import type { z } from "zod"

import type { UnlikelyOrderFulfillmentStatus } from "@unlikelystudio/commerce-connector"

import { ALERT_PRESETS_VALUES, type AlertPresetValues } from "~/lib/alert"
import { COLOR_LABELS } from "~/lib/shopify/constants"
import { fulFillmentStatus } from "~/lib/shopify/constants/fulfillment-statuses"
import { setDictionarySchema } from "~/providers/I18nProvider/utils/set-dictionnary-schema"
import { objectValues } from "~/utils/object-values"

const productKeys = [
  "product_description_title",
  "product_composition_title",
  "product_care_title",
  "back_in_stock_title",
  "product_traceability_title",
  "description_ref_title",
  "stock_alert",
  "product_stock_low",
  "product_details_size",
  "out_of_stock",
  "product_card_success_title",
  "chat_button_text",
] as const

export const badgeKeys = [
  "badge_out_of_stock",
  "badge_new",
  "badge_main_address",
  ...fulFillmentStatus.map(
    (status) => `badge_${status.toLowerCase() as Lowercase<UnlikelyOrderFulfillmentStatus>}` as const
  ),
] as const

export const popinsKeys = ["newsletter_popin_title", "back_in_stock_popin_title"] as const

const ctaKeys = [
  "all_products",
  "cta_discover",
  "add_to_cart",
  "add_to_cart_size",
  "add_to_cart_bundle",
  "slide_next",
  "cta_search",
  "cta_home_page",
  "slide_prev",
  "all_products",
  "more_about_product",
  "discover",
  "cta_back_home",
  "cta_close",
  "cta_open_menu",
  "cta_close_menu",
  "cta_clear_all_filters",
  "cta_apply_filters",
  "cta_submit",
  "cta_validate",
  "cta_cart",
] as const

const ariaKeys = ["aria_arrow_next", "aria_submit", "aria_arrow_prev", "aria_newsletter", "aria_close"] as const

const generalKeys = [
  "account",
  "color",
  "breadcrumbs",
  "shop_the_look",
  "e_reservation",
  "general_variants",
  "legals",
  "faq",
  "navigation_back_button",
  "size_guide",
  "recently_viewed",
  "you_may_also_like",
  "newsletter",
  "general_homepage",
  "size",
  "recommended_for_you",
  "zendesk_chat",
  "lang_switcher",
  "popular_searched_terms",
] as const

const footerKeys = ["footer_legals"] as const

const cartKeys = [
  "cart_quantity",
  "cart_title",
  "cart_go_checkout",
  "cart_estimed_total",
  "cart_total_discount",
  "cart_complete_the_look",
  "cart_taille",
  "cart_couleur",
  "cart_continue_shopping",
  "cart_fixed_popin_label",
  "cart_fixed_popin_size_label",
] as const

const filterKeys = [
  "filter_view",
  "filter_bar_cta",
  "filter_panel_title",
  "search_most_searched_terms",
  "search_previous_searched_terms",
  "search_input_placeholder",
  "search_results_amount",
  "search_no_result",
  "facet_color",
  "facet_discount",
  "facet_promotion",
  "facet_primary_color",
  "facet_size",
  "facet_size_shoes",
  "facet_apparel",
  "facet_categories",
  "facet_sorting",
  "facet_newest",
  "facet_price_asc",
  "facet_price_desc",
  "load_more",
  "load_more_with_amount",
  "load_previous",
] as const

const marketKeys = [
  "market_title",
  "market_country_label",
  "market_language_label",
  "market_submit_label",
  "market_confirm_lang_sentence",
  "market_confirm_lang_button",
  "market_switch_lang_button",
  "market_switch_panel_sentence",
  "market_switch_panel_sentence_button",
] as const

const langSwitcherKeys = ["lang_switcher_footer"] as const

export const formKeys = {
  email: "form_email",
  signup: "form_signup",
  privacy_policy_label: "form_privacy_policy_label",
  newsletter_success: "form_newsletter_success",
  last_name: "form_last_name",
  first_name: "form_first_name",
  contact_subject: "form_contact_subject",
  contact_order_number: "form_contact_order_number",
  contact_message: "form_contact_message",
  required_first_name: "form_required_first_name",
  required_last_name: "form_required_last_name",
  required_email: "form_required_email",
  required_phone: "form_required_phone",
  required_order_number: "form_required_order_number",
} as const

export const videoPlayerKeys = {
  progressBar: "video_progress_label",
  volumeBar: "video_volume_label",
}

export const errorKeys = {
  required: "error_required",
  email: "error_email",
  try_again: "error_try_again",
} as const

export const alertKeys = {
  error_title: "alert_error_title",
  success_title: "alert_success_title",
  info_title: "alert_info_title",
  ...ALERT_PRESETS_VALUES.reduce((acc, alert) => {
    // @ts-ignore
    acc[alert] = `alert_${alert.toLowerCase()}` as const
    return acc
  }, {} as { [key in AlertPresetValues]: `alert_${key}` }),
} as const

export const accountKeys = {
  accountAddAddress: "account_add_address",
  accountAddfirstAddress: "account_add_first_address",
  accountAlreadyHaveAccount: "account_already_have_account",
  accountBillingAddress: "account_billing_address",
  accountChangePassword: "account_change_password",
  accountDeleteAddress: "account_delete_address",
  accountDontHaveAccount: "account_dont_have_account",
  accountDownloadInvoice: "account_download_invoice",
  accountEditAddress: "account_edit_address",
  accountInformationsPage: "account_informations_page",
  accountLogin: "account_log_in",
  accountLoginPrivateSales: "account_log_in_private_sales",
  accountLogout: "account_logout",
  accountMakeDefaultAddress: "account_make_default_address",
  accountOrderDate: "account_order_date",
  accountOrderNumber: "account_order_number",
  accountOrderPrice: "account_order_price",
  accountOrderState: "account_order_state",
  accountNavigation: "account_navigation",
  accountQuantity: "account_quantity",
  accountRegister: "account_register",
  accountResetPassword: "account_reset_password",
  accountShipping: "account_shipping",
  accountShippingAddress: "account_shipping_address",
  accountRegisterPanelTitle: "account_register_panel_title",
  accountSubtotal: "account_subtotal",
  accountTotal: "account_total",
  accountTracking: "account_tracking",
  accountReturn: "account_return",
  accountWelcome: "account_welcome",
  accountYourOrder: "account_your_order",
  accountYourOrders: "account_your_orders",
  accountLoginWith: "account_login_with",
} as const

export const dictionaryKeys = [
  productKeys,
  badgeKeys,
  ariaKeys,
  ctaKeys,
  generalKeys,
  filterKeys,
  footerKeys,
  cartKeys,
  marketKeys,
  popinsKeys,
  langSwitcherKeys,
  objectValues(COLOR_LABELS),
  objectValues(formKeys),
  objectValues(accountKeys),
  objectValues(errorKeys),
  objectValues(alertKeys),
].flat()

export const dictionarySchema = setDictionarySchema(dictionaryKeys)

export type Dictionary = z.infer<typeof dictionarySchema>
